import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationLoaderService {
  private loading = new BehaviorSubject<boolean>(true);
  loading$ = this.loading.asObservable();

  setLoading(isLoading: boolean) {
    this.loading.next(isLoading);
  }
}
